import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import stocksapp from "views/ProfilePage/img/stocksapp.png";
import clubcirclesapp from "views/ProfilePage/img/clubcirclesapp.png";
import drawingapp from "views/ProfilePage/img/drawingapp.png";

import Web from "@material-ui/icons/Web";

import "./ProjectSection.css";

const useStyles = makeStyles(styles);

export default function ProjectSection() {
  const classes = useStyles();
  const imageClasses = classNames(classes.imgRaised, classes.imgFluid);
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Featured Projects</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem xs={12} sm={8} md={8} className={classes.itemGrid}>
                <img src={stocksapp} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle} style={{ color: "#e91e63" }}>
                Stocks Prediction Web App
                <br />
                <small className={classes.smallTitle}>
                  React, Node, Python
                </small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  A web app that can view and predict stocks data using LSTM
                  models and news articles analysis of different queries.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  href="https://github.com/minhancao/Stocks-Prediction-Web-App"
                  target="_blank"
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-github"} />
                </Button>
                <Button
                  justIcon
                  href="https://stocks-prediction-web-app-v1.herokuapp.com/"
                  target="_blank"
                  color="transparent"
                  className={classes.margin5}
                >
                  <Web className={classes.socials} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem xs={12} sm={8} md={8} className={classes.itemGrid}>
                <img src={clubcirclesapp} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle} style={{ color: "#e91e63" }}>
                Club Circles Web App
                <br />
                <small className={classes.smallTitle}>
                  MERN (MongoDB, Express, React, Node) stack
                </small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  A web app that uses the MERN stack to provide a platform for
                  users to host and manage their clubs on.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  href="https://github.com/minhancao/Club_Circles_Web_App"
                  target="_blank"
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-github"} />
                </Button>
                <Button
                  justIcon
                  href="https://clubcircles.herokuapp.com/"
                  target="_blank"
                  color="transparent"
                  className={classes.margin5}
                >
                  <Web className={classes.socials} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem xs={12} sm={8} md={8} className={classes.itemGrid}>
                <img src={drawingapp} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle} style={{ color: "#e91e63" }}>
                Live Drawing Web App
                <br />
                <small className={classes.smallTitle}>
                  React, Node, Socket.IO
                </small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  A web app that allows users to enter a room to view, draw, and
                  discuss strategies on Valorant maps in real-time.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  href="https://github.com/minhancao/valorant-live-map"
                  target="_blank"
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-github"} />
                </Button>
                <Button
                  justIcon
                  href="https://valorant-maps-drawing-app.herokuapp.com/"
                  target="_blank"
                  color="transparent"
                  className={classes.margin5}
                >
                  <Web className={classes.socials} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem xs={12} sm={8} md={8} className={classes.itemGrid}>
                <div className="video-container">
                  <iframe
                    className={classes.imgRaised}
                    title="??"
                    width="560"
                    height="310"
                    src="https://www.youtube.com/embed/tBSqF5JxnY4"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="true"
                  ></iframe>
                </div>
              </GridItem>
              <h4 className={classes.cardTitle} style={{ color: "#e91e63" }}>
                Red Caster Game
                <br />
                <small className={classes.smallTitle}>C#, Unity</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  A 2-D RPG Platformer game that focuses on a character that is
                  able to cast magic spells.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  href="https://github.com/minhancao/RedCaster"
                  target="_blank"
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-github"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
