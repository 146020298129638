/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import { Apps, Assignment, Work, Home } from "@material-ui/icons";

// core components
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import Resume from "assets/files/resume.pdf";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <a href="#About" className={classes.navLink}>
          <Home className={classes.socialIcons} /> Home
        </a>
      </ListItem>
      <ListItem className={classes.listItem}>
        <a href="#ExperienceSection" className={classes.navLink}>
          <Work className={classes.socialIcons} /> Experience
        </a>
      </ListItem>
      <ListItem className={classes.listItem}>
        <a href="#ProjectSection" className={classes.navLink}>
          <Apps className={classes.socialIcons} /> Projects
        </a>
      </ListItem>
      <ListItem className={classes.listItem}>
        <a href={Resume} target="_blank" className={classes.navLink}>
          <Assignment className={classes.socialIcons} /> Resume
        </a>
      </ListItem>
    </List>
  );
}
