import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "views/ProfilePage/img/me4.jpg";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import ExperienceSection from "views/ProfilePage/Sections/ExperienceSection";
import ProjectSection from "views/ProfilePage/Sections/ProjectSection";
import { Email } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid,
    classes.imgGrayscale
  );

  return (
    <div id="About">
      <Header
        color="transparent"
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={
          "https://ak8.picdn.net/shutterstock/videos/1012349138/thumb/1.jpg"
        }
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div style={{ marginBottom: "110px" }}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h1 className={classes.title}>Minhan Cao</h1>
                    <h3 style={{ color: "#999" }}>Software Engineer</h3>
                    <Button
                      justIcon
                      link
                      className={classes.margin5}
                      href="mailto:minhan.duc.cao@gmail.com"
                      target="_blank"
                    >
                      <Email
                        style={{ color: "#e91e63" }}
                        className={classes.socialIcons}
                      />
                    </Button>
                    <Button
                      justIcon
                      link
                      className={classes.margin5}
                      href="https://github.com/minhancao"
                      target="_blank"
                    >
                      <i
                        style={{ color: "#e91e63" }}
                        className={classes.socialIcons + " fab fa-github"}
                      />
                    </Button>
                    <Button
                      justIcon
                      link
                      className={classes.margin5}
                      href="https://www.linkedin.com/in/minh-an-cao/"
                      target="_blank"
                    >
                      <i
                        style={{ color: "#e91e63" }}
                        className={classes.socialIcons + " fab fa-linkedin"}
                      />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p style={{ fontSize: "20px", lineHeight: "200%" }}>
                I am a software engineer based in the Bay Area with experience building full-stack web applications, working with Db2 database, and skills in many programming languages.{" "}
              </p>
            </div>
          </div>
        </div>

        <div id="ExperienceSection" style={{ marginBottom: "30px" }}>
          <ExperienceSection />
        </div>

        <div id="ProjectSection" style={{ marginBottom: "25px" }}>
          <ProjectSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
